<template>
  <div id="previewModal" class="previewmodal">
    <div class="previewmodal-container">
      <!-- <i class="fas fa-times custom_close_btn" @click="handleClose()"></i> -->

      <div @click="tooglePreview()" style="display: inline-block; width: 100%;">
        <span
          style="
            display: inline-block;
            margin-bottom: 20px;
            font-size: 15px;
            font-weight: 600;
          "
        >
          My Bookings
        </span>
        <i
          v-if="preview === false"
          class="bi bi-arrows-angle-expand custom_expand_btn"
        ></i>
        <i v-else class="fas fa-times custom_expand_btn"></i>
      </div>
      <div v-if="event_loading">Loading bookings..</div>
      <div v-else style="max-height: 300px; overflow-y: scroll;">
        <div
          v-for="(item, i) in customer_event_bookings"
          :key="i"
          @click="selectEvent(item)"
        >
          <el-card
            shadow="hover"
            style="margin-bottom: 2px !important; cursor: pointer !important;"
            id="otherBookingsCard"
          >
            <div style="display: inline-block; width: 100%;">
              <!-- <span>Region: {{ item.region }}</span> -->
              <div style="width: 100%; display: block;">
                <div style="width: 50%; display: inline-block;">
                  Region: {{ item.event_region }}
                </div>
                <div style="width: 50%; display: inline-block; float: right;">
                  <el-tag
                    v-if="item.status === 'Attending'"
                    effect="dark"
                    size="small"
                    class="custom-button"
                    style="
                      float: right;
                      color: #67c23a !important;
                      border-color: #67c23a !important;
                    "
                  >
                    {{ item.status }}
                    <!-- <el-button type="primary" size="mini" icon="el-icon-close"></el-button> -->
                    <!--<i class="el-icon-close" style="cursor:pointer;" @click="cancelBooking(item)"></i>-->
                  </el-tag>
                  <el-tag
                    v-else-if="item.status === 'Attended'"
                    type="success"
                    effect="dark"
                    size="small"
                    class="custom-button"
                    style="float: right;"
                  >
                    {{ item.status }}
                  </el-tag>
                  <el-tag
                    v-else
                    type="info"
                    effect="dark"
                    size="small"
                    class="custom-button"
                    style="float: right;"
                  >
                    {{ item.status }}
                  </el-tag>
                </div>
              </div>
              <div style="display: inline-block; width: 100%;">
                <span class="overview-label">Event: {{ item.event_type }}</span>
              </div>
              <div style="display: inline-block; width: 100%;">
                <span style="display: inline-block; float: left;">
                  Booking Date:
                  <!-- {{ item.start_date }}
                  {{ item.start_time }} local time -->

                  {{ getFormatedLocalTime(item) }}
                </span>
                <!-- <span v-if="item.display_status === 'Attending'" style="display: inline-block; width: 20%; text-align: right;"><a href="" style="color: #ff7e7e !important;">Cancel</a></span> -->
              </div>
            </div>

            <!-- <div >
                <span class="overview-label">{{ item.name }}</span>
              </div>
              <div>
                <span class="overview-label">Date Registered: 11/11/11</span>
              </div> -->
          </el-card>
        </div>
      </div>
    </div>

    <!-- <el-dialog  :visible.sync="dialogVisible" title="Tips" width="30%" :draggable="true" style="z-index: 999999 !important;">
    <span>It's a draggable Dialog</span>

      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>

  </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: 'FloatingPreview',
  data() {
    return {
      dialogVisible: true,
      preview: false,
      event_loading: true,
      customer_event_bookings: [],
    }
  },
  created() {
    // this.dialogVisible = true
  },
  methods: {
    getFormatedLocalTime(event) {
      // var d = (event.start_at ? event.start_at.utc : event.start_date) + " UTC";
      var d = event.start_at ? event.start_at.utc : event.start_date

      var new_start = this.$moment(d).format('MMMM DD YYYY, h:mm:ss a') + ' UTC'

      const start_formatted_date = new Date(new_start)

      /* eslint-disable */
      var timeZone = this.$cookies.get('_detected_current_tz')

      if (timeZone === null) {
        var url_timezone =
          'https://api.ipgeolocation.io/timezone?apiKey=' +
          process.env.VUE_APP_COOKIE_KEY
        this.axios
          .get(url_timezone)
          .then((response) => {
            timeZone = response.data.timezone

            var local_date_formatted = new Date(
              start_formatted_date,
            ).toLocaleString('default', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour12: true,
              hour: 'numeric',
              minute: '2-digit',
              timeZoneName: 'short',
              timeZone: timeZone,
            })
            return local_date_formatted
          })
          .catch((error) => {
            console.log('unable to get timezone')
          })
      } else {
        var local_date_formatted = new Date(
          start_formatted_date,
        ).toLocaleString('default', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
          timeZone: timeZone,
        })
        return local_date_formatted
      }
    },
    handleClose() {
      this.$emit('previewClose')
    },
    selectEvent(item) {
      let option = {
        name: 'gotowebinar',
        from_future_booking: true,
      }
      item['option'] = option
      this.$emit('change', item)
    },
    tooglePreview() {
      var element = document.getElementById('previewModal')
      if (this.preview === false) {
        this.preview = true
        // var element = document.getElementById('previewModal');
        element.classList.add('expandModal')
        this.event_loading = false
        this.$store.dispatch('getCustomerBookings').then((response) => {
          if (response.status === 200) {
            this.customer_event_bookings = response.data.data
            this.event_loading = false
          }
        })
      } else {
        this.preview = false
        element.classList.remove('expandModal')
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="courseDialog"
      width="100%"
      top="0%"
      class="course-modal"
      :show-close="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div>
        <el-col :span="24" class="custom-modal-container">
          <div>
            <el-button plain class="custom-button" @click="handleClose()">
              <i class="el-icon-arrow-left"></i>
              All Courses
            </el-button>
          </div>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="16"
            :xl="16"
            class="custom-video-wrapper"
          >
            <el-tabs
              v-model="activeVideoTab"
              @tab-click="handleVideoClick"
              id="videoTabContainer"
              :tab-position="tabPosition"
              style="height: 200px;"
            >
              <el-tab-pane name="video">
                <span slot="label">
                  <i class="el-icon-video-camera modal-tab-icon"></i>
                </span>
                <div
                  v-if="!course.meta.policy.is_accessible"
                  class="video-wrapper"
                  ref="videoWrapper"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  v-bind:style="{
                    'background-image': 'url(' + thumbnail_image + ')',
                  }"
                >
                  <div
                    class="player-container"
                    style="position: relative; min-height: 462px;"
                  >
                    <div
                      class="no-access-sign"
                      style="
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        height: 100%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        background-color: rgba(0, 0, 0, 0.75);
                      "
                    >
                      <i
                        class="el-icon-lock custom-lock-icon"
                        style="font-size: 50px;"
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="video-wrapper"
                  ref="videoWrapper"
                  v-loading="loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <div v-if="is_ended" class="player-container">
                    <div
                      v-bind:style="{
                        height: height,
                      }"
                    >
                      <div class="video-ended-button-container">
                        <el-button @click="handleReplay()">
                          <i class="el-icon-refresh-right"></i>
                          Replay
                        </el-button>
                        <el-button
                          v-if="!end_of_course"
                          type="success"
                          @click="handleNext('next')"
                        >
                          <i class="el-icon-video-play"></i>
                          Play Next Lesson
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="player-container">
                    <component
                      ref="videoModal"
                      :is="vimeoComponent"
                      :video_url="video_url"
                      :lesson_progress="lesson_progress"
                      :active_lesson="selected_lesson"
                      :height="height"
                      :width="width"
                      @videoEnded="videoEnded"
                      @returnProgress="returnProgress"
                      @stopLoading="stopLoading()"
                    ></component>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane v-if="lesson_access" name="resource">
                <span slot="label">
                  <i class="el-icon-notebook-1 modal-tab-icon"></i>
                </span>
                <!-- background-color: rgba( 0, 0, 0, 0.8 ) !important; -->
                <div
                  v-if="!pdfUrl"
                  style="height: 462px; background-color: rgba(0, 0, 0, 0.8);"
                >
                  <span
                    style="
                      font-size: 15px;
                      color: rgb(162, 176, 213);
                      text-align: center;
                      display: inline-block;
                      width: 100%;
                      position: absolute;
                      top: 50%;
                      -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
                    "
                  >
                    There are no Resources for this lesson
                  </span>
                </div>

                <component
                  v-else
                  :is="pdfComponent"
                  :selected="pdfUrl"
                  :lessonId="lessonID"
                  :height="pdfheight"
                ></component>
                <!--<PdfViewer
                  v-if="pdfUrl"
                  :selected="pdfUrl"
                  :lessonId="lessonID"
                  :height="500"
                >
                </PdfViewer>-->
              </el-tab-pane>
            </el-tabs>
            <el-col :span="24">
              <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="Overview" name="overview">
                  <div
                    v-if="overview.title"
                    style="
                      color: #ffffff;
                      font-size: 22px;
                      font-weight: 400;
                      line-height: 28px;
                    "
                  >
                    {{ overview.title }}
                  </div>
                  <div
                    v-if="overview.desc"
                    style="color: #a2b0d5; margin-top: 20px;"
                  >
                    <div
                      v-html="overview.desc"
                      style="word-break: break-word; text-align: justify;"
                    ></div>
                  </div>
                </el-tab-pane>
                <el-tab-pane v-if="this.lesson_access" label="FAQ" name="faq">
                  <div
                    style="
                      color: #ffffff;
                      font-size: 22px;
                      font-weight: 400;
                      line-height: 28px;
                    "
                  >
                    <p
                      v-if="faqs.length === 0"
                      style="font-size: 15px; color: rgb(162, 176, 213);"
                    >
                      There are no FAQ's for this lesson
                    </p>
                    <el-collapse v-else id="faqAccordion" v-model="faqActive">
                      <el-collapse-item
                        v-for="(faq, i) in faqs"
                        :key="i"
                        :name="i"
                      >
                        <template slot="title">
                          <i
                            class="header-icon el-icon-info"
                            style="margin-right: 10px;"
                          ></i>
                          {{ faq.question }}
                        </template>
                        <div
                          v-if="faq.answer"
                          style="
                            color: #a2b0d5;
                            margin-top: 20px;
                            padding-left: 23px;
                            padding-right: 30px;
                          "
                        >
                          <div
                            v-html="faq.answer"
                            style="word-break: break-word; text-align: justify;"
                          ></div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <!-- <div class="card-title">{{ course.title }} {{ course.count }}</div> -->
            <el-col
              :span="24"
              id="rightColumnContainer"
              class="right-column-container"
              style="margin-bottom: 10px;"
            >
              <el-collapse
                v-model="activeName"
                class="custom-callapse"
                @change="handleChange"
                accordion
              >
                <el-collapse-item
                  v-for="(chapter, i) in chapters"
                  :name="i"
                  :key="i"
                  v-bind:style="{
                    'background-image':
                      'url(' +
                      require('../../assets/images/' + getBackground(i)) +
                      ')',
                  }"
                  style="
                    background-size: 100% 68px;
                    background-repeat: no-repeat;
                  "
                >
                  <template slot="title">
                    <el-card class="box-card card-accordion" shadow="never">
                      <div style="line-height: normal;" class="">
                        {{ chapter.name }}
                      </div>
                      <div style="line-height: normal;">
                        {{
                          _chapter_progress[chapter.id]
                            ? _chapter_progress[chapter.id]['total_completed']
                            : 0
                        }}
                        / {{ chapter.lessons.length }}
                      </div>
                    </el-card>
                  </template>

                  <div>
                    <el-col
                      v-for="(lesson, index) in chapter.lessons"
                      :span="24"
                      style="margin-bottom: 10px;"
                      :key="index"
                      :class="['accordion-chapter-' + chapter.id]"
                    >
                      <el-col
                        :span="24"
                        class="modal-lesson-item"
                        :id="['accordion_item_' + chapter.id + lesson.id]"
                      >
                        <!-- :class="[lesson.is_completed === 1 ? 'done' : ' ']" -->
                        <div
                          class="modal-lesson-item-container"
                          @click="getActiveLesson(lesson, i, index)"
                        >
                          <div class="lesson-title">
                            {{ index + 1 }}. {{ lesson.name }}
                          </div>
                          <!--{{
                              _lesson_progress[lesson.id]
                                ? _lesson_progress[lesson.id]['is_complete']
                                : 0
                            }} -->
                          <!-- if accessible -->
                          <div
                            v-show="course.course_type === COURSE_TYPE_DEFAULT"
                            v-if="lesson.meta.policy.is_accessible"
                            class="lesson-duration"
                          >
                            <span
                              v-if="lessonProgressStatus(lesson.id) === 2"
                              style="color: #a4f14a;"
                            >
                              <i class="el-icon-check custom-check"></i>
                            </span>
                            <span v-if="lessonProgressStatus(lesson.id) === 1">
                              <i class="el-icon-video-play"></i>
                              Continue
                            </span>
                            <span v-if="lessonProgressStatus(lesson.id) === 0">
                              <i class="el-icon-video-play"></i>
                              <span>
                                {{ computeDuration(lesson.video.duration) }}
                              </span>
                            </span>
                          </div>
                          <!-- if not accessible -->
                          <div v-else class="lesson-duration">
                            <i class="el-icon-lock"></i>
                          </div>
                          <el-progress
                            v-show="course.course_type === COURSE_TYPE_DEFAULT"
                            v-if="lessonProgressStatus(lesson.id) === 1"
                            style="width: 90% !important; margin-top: 5px;"
                            :percentage="Math.round(getLessonProgress(lesson))"
                            :show-text="false"
                            :stroke-width="2"
                            define-back-color="#5F5A7A"
                            :color="
                              lesson.id === selected_lesson.id
                                ? '#A4F14A'
                                : '#999'
                            "
                          ></el-progress>
                          <!-- <div class="lesson-duration">
                            <i class="el-icon-video-play"></i>
                            {{ lesson.video.duration }}
                          </div> -->
                        </div>
                      </el-col>
                    </el-col>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-col>
        </el-col>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VideoComponent from './VideoComponent.vue'
import PdfViewer from './PdfViewer.vue'
export default {
  name: 'CourseModal',
  components: {
    VideoComponent,
    PdfViewer,
  },
  props: {
    course: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      courseDialog: true,
      activeTab: 'overview',
      tabPosition: 'left',
      card_bg: require('../../assets/images/card-bg.png'),
      chapters: [],
      video_url: '',
      playerReady: false,
      height: 0,
      width: 0,
      options: {
        muted: true,
        autoplay: true,
      },
      vimeoComponent: null,
      overview: {
        title: '',
        desc: '',
      },
      faqs: [],
      faqActive: '',

      activeName: 0,
      pdfUrl: '',
      activeVideoTab: 'video',
      lessonID: null,
      pdfComponent: null,
      pdfheight: 500,
      selected_lesson: {},
      progress: 0,
      lesson_progress: 0,
      thumbnail_image: '',
      COURSE_TYPE_DEFAULT: 1,
      lesson_access: this.course.meta.policy.is_accessible,
      loading: true,
      is_ended: false,
      end_of_course: false,
      banner_bgs: [
        'whats-new.png',
        'stock-indices.png',
        'lxf-webinars.png',
        'learn-the-basics.png',
        'learn-forex.png',
        'how-to-use-smartcharts.png',
      ],
    }
  },
  mounted() {
    this.getLessons()
    this.getDimension()
    setTimeout(() => {
      this.getFirstSelected()
    }, 1000)
  },
  computed: {
    _my_progress() {
      return this.$store.getters._my_progress
    },
    _chapter_progress() {
      let lessons = this._my_progress.lessons
      let groupedByChapter = lessons.reduce((i, item) => {
        const chapter_id = item.chapter_id
        if (!i[chapter_id]) {
          i[chapter_id] = []
        }
        i[chapter_id].push(item)
        return i
      }, {})

      const chapters = []

      for (var index in groupedByChapter) {
        chapters[index] = []
        let lessons = groupedByChapter[index]
        let completed = lessons.filter((lesson) => {
          return lesson.is_complete === 1
        })
        chapters[index]['total_completed'] = completed.length
        chapters[index]['lesson_progress'] = lessons
      }
      return chapters
    },
    _lesson_progress() {
      var lessons = []
      let progress = this._my_progress.lessons
      for (var index in progress) {
        let key = progress[index]['lesson_id']
        lessons[key] = this._my_progress.lessons[index]
      }
      return lessons
    },
  },
  methods: {
    getBackground(index) {
      /* eslint-disable */
      var last_digit = Math.floor(index % 10)
      if (last_digit > 5) {
        last_digit = last_digit % 6
      }
      return this.banner_bgs[last_digit].toString()
    },
    handleReplay() {
      this.loading = true
      this.is_ended = false
      this.lesson_progress = 0
      this.$nextTick(() => {
        this.$refs.videoModal.handleReplay()
      })
      this.loading = false
    },
    handleNext(mode) {
      /* eslint-disable */
      var chapters = this.chapters
      var selected_lesson_id = this.selected_lesson.id
      var chapter_id = this.selected_lesson.chapter_id

      // check if it is already last chapter
      var chapter_count = chapters.length
      let chapter_index = chapters.findIndex(function (chapter) {
        return chapter.id === chapter_id
      })

      let selected_chapter = chapters.filter((chapter) => {
        return chapter.id === chapter_id
      })

      var lesson_index = selected_chapter[0].lessons.findIndex(function (les) {
        return les.id === selected_lesson_id
      })

      let next_lesson = selected_chapter[0].lessons[lesson_index + 1]
      if (!next_lesson) {
        if (chapter_index + 1 < chapter_count) {
          // if still have next chapter
          selected_chapter = chapters[chapter_index + 1]
          next_lesson = selected_chapter.lessons[0]

          if (mode === 'next') {
            this.activeName = chapter_index + 1
            this.is_ended = this.loading = false
            this.getActiveLesson(next_lesson)
            this.end_of_course = false
          }
        } else {
          if (mode !== 'next') {
            this.end_of_course = true
          }
          console.log('end all')
          // this.end_of_course = true
        }
      } else {
        if (mode === 'next') {
          this.is_ended = this.loading = false
          this.getActiveLesson(next_lesson)
          this.end_of_course = false
        }
      }
    },
    videoEnded() {
      this.is_ended = true
      this.loading = false
      this.handleNext()
    },
    lessonProgressStatus(lesson_id) {
      var status = 0
      var progress = this._lesson_progress[lesson_id]

      if (progress) {
        if (progress['is_complete'] === 0) {
          status = 1
        } else {
          status = 2
        }
      }
      return status
    },
    computeDuration(value) {
      const minutes = Math.floor(value / 60)
      return minutes + ' mins'
    },
    getLessonProgress(data) {
      let progress = this._my_progress
      /* eslint-disable */
      let x = 0
      // data.lessons.forEach((les, index) => {
      progress.lessons.forEach((lesson, i) => {
        if (lesson.lesson_id === data.id) {
          x = lesson.duration
          // this.lesson_progress = x;
        }
      })

      return x
    },
    getChapterProgress(chapter_id) {
      let progress = this._my_progress
      let completed = progress.filter((lesson) => {
        return lesson.is_complete === 1 && lesson.chapter_id === chapter_id
      })

      return completed.length
    },
    returnProgress(data, lesson) {
      this.progress = data
      // this.progress = (data / lesson.video.duration) * 100
      // console.log(Math.round(this.progress), 'return progress')
      // if (this.progress > 100) {
      //   this.progress = 100
      // }
      let temp_chapters = this.chapters

      temp_chapters.forEach((element) => {
        if (element.id === lesson.chapter_id) {
          element.lessons.forEach((chapter_lesson) => {
            if (chapter_lesson.id === lesson.id) {
              chapter_lesson['progress'] = data
            }
          })
        }
      })
      this.chapters = []
      this.chapters = temp_chapters
    },
    getFirstSelected() {
      /* eslint-disable */
      // Set first active lesson on load
      var chapter_id = 0
      var lesson_id = 0

      if (this.course.last_viewed_lesson) {
        chapter_id = this.course.last_viewed_lesson.chapter_id
        lesson_id = this.course.last_viewed_lesson.lesson_id

        var chapter_index = this.chapters.findIndex(function (chapter) {
          return chapter.id === chapter_id
        })
        this.activeName = chapter_index
      }
      let start_point = '' + chapter_id + lesson_id
      var idName = 'accordion_item_' + start_point
      let firstSelectedElement = document.getElementById(idName)

      if (firstSelectedElement) {
        this.$nextTick(() => {
          firstSelectedElement.classList.add('active')
        })
      }
    },
    getDimension() {
      this.$nextTick(() => {
        const element = this.$refs.videoWrapper
        const width = element.offsetWidth
        const height = element.offsetHeight
        this.height = height
        this.width = width
        var w = window.innerWidth
        if (w < 480) {
          this.tabPosition = 'top'
          this.pdfheight = 360
        }
      })
    },
    getActiveLesson(data, mode) {
      // this.end_of_course = false
      this.is_ended = false
      this.end_of_course = false
      this.lesson_access = data.meta.policy.is_accessible
      this.lesson_progress = 0
      if (this.lessonID !== data.id) {
        let lessonId = data.id
        const config = {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
            'X-Session-Key': localStorage.getItem('token'),
          },
        }

        var url =
          process.env.VUE_APP_API_URL + '/api/lmsapp/lessons/' + lessonId
        this.axios
          .get(url, config)
          .then((response) => {
            let lesson = response.data.data
            // Remove all active class on modal-lesson-item classes
            let removeElement = document.getElementsByClassName(
              'modal-lesson-item',
            )
            if (removeElement) {
              for (var i = 0; i < removeElement.length; i++) {
                removeElement[i].classList.remove('active')
              }
            }
            this.vimeoComponent = null
            this.pdfComponent = null
            this.$nextTick(() => {
              this.selected_lesson = lesson
              this.overview.title = lesson.name
              this.overview.desc = lesson.description
              this.faqs = lesson.faq
              this.pdfUrl = ''
              if (lesson.resources) {
                this.pdfUrl = lesson.resources.image.attachment_absolute_path
                this.pdfComponent = PdfViewer
              }
              if (!lesson.meta.policy.is_accessible) {
                this.thumbnail_image = lesson.image.attachment_absolute_path
              }
              this.video_url = lesson.video.video_url
              this.vimeoComponent = VideoComponent
              this.lessonID = lesson.id

              let _my_progress = this._my_progress

              _my_progress.lessons.forEach((lesson, i) => {
                if (lesson.lesson_id === data.id) {
                  console.log(lesson, 'lesson-prohresssss')
                  this.lesson_progress = lesson.duration
                }
              })
              if (this.lessonProgressStatus(lesson.id) === 2) {
                // if lesson progress is done, lesson will start from the beginning on click
                this.lesson_progress = 0
              }
            })

            this.assignClass(data.chapter_id, data.id, mode)
          })
          .catch((error) => {})
      } else {
        console.log('same lesson selected')
      }
    },
    getLessonIndex(chapter_id, lesson_id) {
      var top_index = 1

      let _chapters = this.chapters
      _chapters.forEach((element) => {
        if (element.id === chapter_id) {
          element.lessons.forEach((chapter_lesson, i) => {
            if (chapter_lesson.id === lesson_id) {
              top_index = i
            }
          })
        }
      })

      return top_index
    },
    assignClass(chapter_id, lesson_id, mode) {
      console.log(mode, 'mode')
      // assign active class on accordion item
      var class_name = 'accordion_item_' + chapter_id + lesson_id

      var top_index = this.getLessonIndex(chapter_id, lesson_id)
      var topValue = 72 * (top_index + 1)

      let element = document.getElementById(class_name.toString())

      if (element) {
        setTimeout(() => {
          element.classList.add('active')
          //   element.scrollIntoView({ behavior: 'smooth' })
          //   document.getElementById(class_name).click()
          if (mode === 'firstLoad') {
            document
              .getElementById('rightColumnContainer')
              .scrollTo({ top: topValue, behavior: 'smooth' })
          }
        }, 40)
      }
    },
    getLessons() {
      let id = this.course.id
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          'X-Session-Key': localStorage.getItem('token'),
        },
      }

      var url =
        process.env.VUE_APP_API_URL + '/api/lmsapp/courses/' + id + '/chapters'
      this.axios
        .get(url, config)
        .then((response) => {
          this.chapters = response.data.data
          this.vimeoComponent = VideoComponent

          var chapter_id = 0
          var lesson_id = 0
          console.log(this.course, '-----')
          if (this.course.last_viewed_lesson) {
            chapter_id = this.course.last_viewed_lesson.chapter_id
            lesson_id = this.course.last_viewed_lesson.lesson_id
          }
          let active_leson = null
          if (this.course.meta.policy.is_accessible) {
            // find the index of chapters
            // get index of chapters and lessons
            if (this.course.last_viewed_lesson) {
              this.chapters.forEach((chapter, i) => {
                if (this.course.last_viewed_lesson.chapter_id === chapter.id) {
                  chapter.lessons.forEach((lesson, index) => {
                    if (
                      lesson.id === this.course.last_viewed_lesson.lesson_id
                    ) {
                      active_leson = lesson
                    }
                  })
                }
              })
            }

            if (active_leson) {
              this.getActiveLesson(active_leson, 'firstLoad')
              this.selected_lesson = active_leson
            } else {
              this.getActiveLesson(this.chapters[0].lessons[0], 'firstLoad')
              this.selected_lesson = this.chapters[0].lessons[0]
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleClose() {
      this.courseDialog = false
      this.$emit('unload')
    },
    handlePLay() {},
    handleClick(tab, event) {},
    onReady() {
      this.playerReady = true
    },
    play() {
      this.$refs.player.play()
    },
    pause() {
      this.$refs.player.pause()
    },
    stopLoading() {
      this.loading = false
    },
    handleChange(val) {},
    handleSetActive(index) {},
    handleVideoClick() {
      this.$refs.videoModal.handleTriggerPause()
    },
  },
}
</script>

<style scoped></style>

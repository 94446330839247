<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import Index from './components/Index.vue'
/* eslint-disable */
export default {
  name: 'App',
  components: {
    Index,
  },
  data() {
    return {
      verification: false,
      token: '',
    }
  },
  created() {},
  beforeCreate() {
    document.title = 'E learning'
  },
  methods: {},
}
</script>

<style></style>

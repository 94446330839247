var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row full-height"},[_c('el-row',[_c('el-col',{staticClass:"right-panel",attrs:{"span":24}},[_c('el-row',{attrs:{"gutter":30}},_vm._l((_vm.courses),function(course,i){return _c('el-col',{key:i,staticStyle:{"margin-top":"30px"},attrs:{"xs":24,"sm":24,"md":12,"lg":8,"xl":8}},[_c('el-card',{staticClass:"box-card custom-card",staticStyle:{"background-size":"cover"},style:({
              'background-image': 'url(' + _vm.getBackground(course.image) + ')',
            }),attrs:{"shadow":"hover"}},[_c('div',{on:{"click":function($event){return _vm.getCourse(course)}}},[_c('div',{staticClass:"card-head"},[_c('div',{staticClass:"card-title",style:({
                    color:
                      course.meta.policy.is_accessible === true
                        ? '#ffffff'
                        : '#A2B0D5',
                    width: _vm.window.width > 430 ? '75%' : '70%',
                  })},[_vm._v(" "+_vm._s(course.name)+" ")]),(course.meta.policy.is_accessible === true)?_c('div',{staticClass:"card-progress"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(course.course_type === _vm.COURSE_TYPE_DEFAULT),expression:"course.course_type === COURSE_TYPE_DEFAULT"}],staticClass:"card-percentage",style:({
                      width: _vm.window.width > 430 ? '13%' : '15%',
                    })},[_vm._v(" "+_vm._s(_vm.progressByCourse[course.id] ? _vm.progressByCourse[course.id]['total_completed'] : 0)+" / "+_vm._s(course.total_lessons)+" ")]),(course.course_type === _vm.COURSE_TYPE_DEFAULT)?_c('div',[_c('progress-circle',{attrs:{"diameter":50,"circle-width":3,"completed-steps":!parseFloat(course.total_lessons)
                          ? 0
                          : parseFloat(
                              _vm.computePercentage(
                                _vm.progressByCourse[course.id]
                                  ? _vm.progressByCourse[course.id][
                                      'total_completed'
                                    ]
                                  : 0,
                                course.total_lessons,
                              ),
                            ),"circle-color":"#194378","start-color":"#77BC43","stop-color":"#77BC43","total-steps":100}},[_c('p',[_vm._v(" "+_vm._s(!parseFloat(course.total_lessons) ? 0 : parseFloat( _vm.computePercentage( _vm.progressByCourse[course.id] ? _vm.progressByCourse[course.id][ 'total_completed' ] : 0, course.total_lessons, ), ))+" % ")])])],1):_vm._e()]):_c('div',{staticClass:"lock"},[_c('i',{staticClass:"el-icon-lock"})])]),_c('div',{staticClass:"card-description"},[_c('div',{staticClass:"vertical-center"},[_c('div',{staticClass:"trim-desciption",domProps:{"innerHTML":_vm._s(course.description)}})])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"block-avatar"},[_c('el-avatar',{staticClass:"custom-avatar",attrs:{"size":30,"src":""},on:{"error":_vm.errorHandler}},[_c('img',{attrs:{"src":course.author.name === 'Greg Secker'
                          ? _vm.avatar_gregg
                          : _vm.avatar_joy}})]),_c('div',{staticClass:"speaker-block"},[_c('div',{staticStyle:{"color":"#a2b0d5","font-size":"12px"}},[_vm._v(" Speaker: ")]),_c('div',{staticClass:"speaker-name"},[_vm._v(_vm._s(course.author.name))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(course.course_type === _vm.COURSE_TYPE_DEFAULT),expression:"course.course_type === COURSE_TYPE_DEFAULT"}],staticClass:"block-duration"},[_c('div',{staticStyle:{"color":"#a2b0d5","font-size":"12px"}},[_vm._v(" Total duration: ")]),_c('div',{staticClass:"speaker-name"},[_vm._v(" "+_vm._s(_vm.computeDuration(course.duration))+" ")])])])])])],1)}),1)],1)],1),_c(_vm.currentComponent,{tag:"component",attrs:{"course":_vm.selectedCourse},on:{"unload":function($event){return _vm.unload()}}}),_c(_vm.floatingComponent,{tag:"component"}),_c(_vm.complianceComponent,{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
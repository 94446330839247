<template>
  <div>
    <vimeo-player
      v-if="video_url"
      ref="player"
      :video-url="video_url"
      :player-width="width"
      :player-height="height"
      :options="options"
      muted="muted"
      @ready="onReady"
      @play="handlePlay"
      @pause="handlePause"
      @playing="handlePlaying"
      @cuepoint="handleCuePoints"
      @ended="handleEnded"
      @timeupdate="handleTimeUpdate"
      @progress="handleProgress"
      @seeking="handleSeeking"
      @seeked="handleSeeked"
      @resize="handleResize"
      @error="handleError"
      @loaded="handleLoaded"
      @durationchange="handleDurationChange"
      @fullscreenchange="handleFullscren"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
/**
 * Debounce trailing time: in milliseconds
 * this is 1 second less than the interval time on
 * handleTimeUpdate method
 */
const DEBOUNCE_TRAILING_TIME = 1000
const API_CALL_INTERVAL = 2

var debounceApiCall = _.debounce(function (params) {
  const config = {
    headers: {
      //Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'X-Session-Key': localStorage.getItem('token'),
    },
  }

  var url = process.env.VUE_APP_API_URL + '/api/lmsapp/lessons/progress'
  var data = {
    payload: [params],
  }
  axios
    .post(url, data, config)
    .then((response) => {
      console.log(response, 'response')
    })
    .catch((error) => {
      console.log(error)
    })
}, DEBOUNCE_TRAILING_TIME)

function callLessonProgressApi(params) {
  const config = {
    headers: {
      //Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'X-Session-Key': localStorage.getItem('token'),
    },
  }

  var url = process.env.VUE_APP_API_URL + '/api/lmsapp/lessons/progress'
  var data = {
    payload: [params],
  }
  axios
    .post(url, data, config)
    .then((response) => {
      console.log(response, 'response')
    })
    .catch((error) => {
      console.log(error)
    })
}
export default {
  /* eslint-disable */
  name: 'VideoComponent',
  props: {
    height: {
      type: Number,
      required: true,
    },
    active_lesson: {
      type: Object,
      required: true,
    },
    lesson_progress: {
      type: Number,
      default: 0,
    },
    // progress: {
    //   type: Number,
    //   default: 0,
    // },
    // index: {
    //   type: Number,
    //   required: true,
    // },
    // active_course: {
    //   type: Object,
    //   required: true,
    // },
    // lesson_id: {
    //   type: Number,
    //   required: false,
    // },
    width: {
      type: Number,
      required: true,
      default: 1280,
    },
    video_url: {
      type: String,
      required: true,
    },
    // current_time: {
    //   type: Number,
    //   required: true,
    // },
    // is_complete: {
    //   type: Number,
    //   required: false,
    // },
    // course_id: {
    //   type: Number,
    //   required: true,
    // },
    // course_type: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      options: {
        //muted: true,
        autoplay: true,
        responsive: true,
      },
      playerReady: false,
      videoUrl: '',
      is_live_event: false,
      is_complete_: false,
      lesson_duration: 0,
      video_id: null,
      timer: null,
      current_time: 0,
      pause: false,
    }
  },

  mounted() {
    this.timer = setInterval(() => {
      this.eventTimer()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.videoUrl = this.video_url
    // this.$store.dispatch("syncProgressState", {});
  },
  methods: {
    eventTimer() {
      // let video_id = this.video_id
      let time = 10
      let item = {
        current_time: time,
        duration: time,
        course_id: this.course_id,
        video_id: this.video_id,
        lesson_id: this.active_lesson.id,
        type: 'default',
      }
      if (this.is_live_event === true) {
        item.type = 'live_event'
        this.$store.dispatch('updatelessonProgress', item)
      }
    },
    handleResize(event, data) {
      this.$emit('onResize', event)
    },
    handleDurationChange(event, data) {
      console.log(event, data, 'duration change')
    },
    unloadVideo(value) {
      this.$refs.player.player
        .unload()
        .then(function () {
          // the player was destroyed
          // this.loadVideo(value)
        })
        .catch(function (error) {
          // an error occurred
        })
    },
    loadVideo(value) {
      let str = ''
      if (value) {
        str = value
      }
      let video_id = null
      video_id = str.split('m/').pop()
      this.$refs.player.player
        .loadVideo(video_id)
        .then(function (id) {
          // the video successfully loaded
          this.handleReplay()
        })
        .catch(function (error) {
          switch (error.name) {
            case 'TypeError':
              // the id was not a number
              break

            case 'PasswordError':
              // the video is password-protected and the viewer needs to enter the
              // password first
              break

            case 'PrivacyError':
              // the video is password-protected or private
              break

            default:
              // some other error occurred
              break
          }
        })
    },
    handleReplay(value) {
      let current_progress = 0
      this.$refs.player.player
        .setCurrentTime(0)
        .then(function (seconds) {
          // seconds = the actual time that the player seeked to
          this.playerReady = true
        })
        .catch(function (error) {
          switch (error.name) {
            case 'RangeError':
              // the time was less than 0 or greater than the video’s duration
              break

            default:
              // some other error occurred
              break
          }
        })
    },
    handleTimeUpdate(event, data) {
      let progress = event.seconds
      let duration = parseFloat(Number(event.percent * 100).toFixed(2))
      let activeLesson = this.active_lesson
      let params = {
        duration: duration,
        // duration: (event.seconds / this.active_lesson.video.duration) * 100,
        course_id: activeLesson.course_id,
        video_id: activeLesson.video.video_id,
        lesson_id: activeLesson.id,
        chapter_id: activeLesson.chapter_id,
        casesafe_id: localStorage.getItem('customer_id'),
        viewed_at: Math.floor(Date.now() / 1000),
        is_complete: duration > 90 ? 1 : 0,
      }
      if (Math.ceil(event.seconds) % API_CALL_INTERVAL === 0 && duration > 90) {
        callLessonProgressApi(params)
      }
      if (Math.ceil(event.seconds) % API_CALL_INTERVAL === 0 && duration < 90) {
        debounceApiCall(params)
      }

      this.$nextTick(() => {
        this.$emit('returnProgress', event.percent, this.active_lesson)
        let params = {
          duration: parseFloat(Number(event.percent * 100).toFixed(2)),
          // duration: (event.seconds / this.active_lesson.video.duration) * 100,
          course_id: activeLesson.course_id,
          video_id: activeLesson.video.video_id,
          lesson_id: activeLesson.id,
          chapter_id: activeLesson.chapter_id,
          casesafe_id: localStorage.getItem('customer_id'),
          viewed_at: Math.floor(Date.now() / 1000),
        }
        this.$store.dispatch('updateLessonProgress', params)
        // this.$store.dispatch('updateLessonProgress', params)
      })

      // if(this.is_complete === false) {
      //   setTimeout(() => {
      //   }, 5000);
      // }
    },
    percentage(num, per) {
      return (num / 100) * per
    },
    handlePlay(event, data, player) {
      this.lesson_duration = event.duration
      this.video_duration = event.duration
      if (this.pause === false) {
        this.setCurrentTime(event) // Bug fix for jumping track on resume
      } else {
        this.pause = false
      }
    },
    handlePlaying(event, data) {
      // if (event.duration === 0) {
      //   this.is_live_event = true;
      // }
      // setTimeout(() => {
      // }, 5000);
    },
    onReady(event) {
      this.$refs.player.player.setVolume(1) //setting volume to 1
      this.current_time = this.lesson_progress
      this.$refs.player
        .play()
        .then(function () {
          this.playerReady = true
        })
        .catch(function (error) {
          switch (error.name) {
            case 'PasswordError':
              // The video is password-protected
              break
            case 'PrivacyError':
              // The video is private
              break
            default:
              // Some other error occurred
              break
          }
        })
      this.$emit('stopLoading')
    },
    setCuePoints(value) {
      this.$refs.player.player
        .addCuePoint(value, {
          customKey: 'customValue',
        })
        .then(function (id) {
          // cue point was added successfully
        })
        .catch(function (error) {
          switch (error.name) {
            case 'UnsupportedError':
              // cue points are not supported with the current player or browser
              break

            case 'RangeError':
              // the time was less than 0 or greater than the video’s duration
              break

            default:
              // some other error occurred
              break
          }
        })
    },
    handleCuePoints(event, data) {
      let item = {
        current_time: event.time,
        duration: event.duration,
        course_id: this.course_id,
        video_id: this.video_id,
        lesson_id: this.active_lesson.id,
        type: 'default',
      }
      this.$store.dispatch('updatelessonProgress', item)
    },
    setCurrentTime(event) {
      let current_progress = (this.current_time / 100) * event.duration

      console.log(current_progress, 'current_progress')
      // let cur_time = (this.current_time / 100) * event.duration

      this.$refs.player.player
        .setCurrentTime(current_progress)
        .then(function (seconds) {
          console.log(seconds, 'setcurrenttime')
          // seconds = the actual time that the player seeked to
        })
        .catch(function (error) {
          switch (error.name) {
            case 'RangeError':
              // the time was less than 0 or greater than the video’s duration
              break

            default:
              // some other error occurred
              break
          }
        })
    },
    handleFullscren() {},
    handleLoaded(event, data) {
      this.video_id = event.id

      let dimension = {
        height: 0,
        width: 0,
      }
      let x = Promise.all([
        this.$refs.player.player.getVideoWidth(),
        this.$refs.player.player.getVideoHeight(),
      ]).then((response) => {
        var width = response[0] >= 960 ? response[0] : 960
        var height = response[1] >= 540 ? response[1] : 540
        dimension = {
          height: height,
          width: width,
        }
        this.$emit('loaded', event)
      })
    },
    handleError(event, data) {
      this.$emit('stopLoading')
      if (this.videoUrl.includes('event')) {
      } else {
        this.$emit('failed')
      }
    },
    handleTriggerPause() {
      this.$refs.player.pause()
    },
    handlePause(event, data) {
      this.current_time = event.seconds
      this.pause = true
      this.$refs.player.pause()
    },
    handleEnded(event, data) {
      let activeLesson = this.active_lesson
      let params = {
        duration: 100,
        // duration: (event.seconds / this.active_lesson.video.duration) * 100,
        course_id: activeLesson.course_id,
        video_id: activeLesson.video.video_id,
        lesson_id: activeLesson.id,
        chapter_id: activeLesson.chapter_id,
        casesafe_id: localStorage.getItem('customer_id'),
        viewed_at: Math.floor(Date.now() / 1000),
        is_complete: 1,
      }
      callLessonProgressApi(params)
      this.$emit('videoEnded', data)
    },
    handleProgress(event, data) {
      // this.$nextTick(() => {
      //   console.log(event, data, 'progresssss')
      //   this.$emit('returnProgress', event.percent, this.active_lesson)
      //   let activeLesson = this.active_lesson
      //   let params = {
      //     duration: parseFloat(Number(event.percent * 100).toFixed(2)),
      //     course_id: activeLesson.course_id,
      //     video_id: activeLesson.video.video_id,
      //     lesson_id: activeLesson.id,
      //     chapter_id: activeLesson.chapter_id,
      //     casesafe_id: localStorage.getItem('customer_id'),
      //     viewed_at: Math.floor(Date.now() / 1000),
      //   }
      //   this.$store.dispatch('updateLessonProgress', params)
      // })
    },
    handleSeeking(event, data) {},
    handleSeeked() {},
  },
}
</script>

<style scoped></style>

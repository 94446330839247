// import 'dotenv/config'

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import '/src/assets/styles/style.css';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n
import { IconsPlugin } from 'bootstrap-vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';
import VueCoreVideoPlayer from 'vue-core-video-player';
import ElementUI from 'element-ui';
// import CountryFlag from 'vue-country-flag';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLazyload from 'vue-lazyload';
import VueLazyImageLoading from 'vue-lazy-image-loading';
import VueMoment from 'vue-moment';
import VueCookies from 'vue-cookies';
import moment from 'moment-timezone';
import vueVimeoPlayer from 'vue-vimeo-player';
// import VueEllipseProgress from 'vue-ellipse-progress';
import { ProgressCircle } from 'vue-progress-circle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'video.js/dist/video-js.css';

Vue.component(
	'font-awesome-icon',
	FontAwesomeIcon
);

Vue.use(IconsPlugin);
Vue.use(VueRouter);

Vue.use(VueCookies, {
	expires: '1y',
	path: '/',
	domain:
		process.env.VUE_APP_COOKIE_DOMAIN ?? '', //should be from .env
	secure: true,
	sameSite: 'None',
});
Vue.use(vueVimeoPlayer);

Vue.use(VueLazyload);
Vue.use(VueLazyImageLoading, {
	cache: true,
});
Vue.use(VueMoment, {
	moment,
});
Vue.use(VueCoreVideoPlayer);
Vue.use(ProgressCircle);
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

const router = new Router({});

//production and development only. Please dont use this at local
// var appEnv = process.env.VUE_APP_ENV;

new Vue({
	router,
	render: (h) => h(App),
	store: store,
}).$mount('#app');

<template>
  <div class="row full-height">
    <el-row>
      <el-col :span="24" class="right-panel">
        <el-row :gutter="30">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="(course, i) in courses"
            :key="i"
            style="margin-top: 30px;"
          >
            <el-card
              class="box-card custom-card"
              v-bind:style="{
                'background-image': 'url(' + getBackground(course.image) + ')',
              }"
              style="background-size: cover;"
              shadow="hover"
            >
              <div @click="getCourse(course)">
                <div class="card-head">
                  <div
                    class="card-title"
                    :style="{
                      color:
                        course.meta.policy.is_accessible === true
                          ? '#ffffff'
                          : '#A2B0D5',
                      width: window.width > 430 ? '75%' : '70%',
                    }"
                  >
                    {{ course.name }}
                  </div>
                  <div
                    v-if="course.meta.policy.is_accessible === true"
                    class="card-progress"
                  >
                    <div
                      class="card-percentage"
                      v-show="course.course_type === COURSE_TYPE_DEFAULT"
                      :style="{
                        width: window.width > 430 ? '13%' : '15%',
                      }"
                    >
                      <!-- {{ courseProgress(course.id).total_completed.length }} / {{ course.total_lessons }} -->
                      {{
                        progressByCourse[course.id]
                          ? progressByCourse[course.id]['total_completed']
                          : 0
                      }}
                      / {{ course.total_lessons }}
                    </div>
                    <div v-if="course.course_type === COURSE_TYPE_DEFAULT">
                      <progress-circle
                        :diameter="50"
                        :circle-width="3"
                        :completed-steps="
                          !parseFloat(course.total_lessons)
                            ? 0
                            : parseFloat(
                                computePercentage(
                                  progressByCourse[course.id]
                                    ? progressByCourse[course.id][
                                        'total_completed'
                                      ]
                                    : 0,
                                  course.total_lessons,
                                ),
                              )
                        "
                        circle-color="#194378"
                        start-color="#77BC43"
                        stop-color="#77BC43"
                        :total-steps="100"
                      >
                        <p>
                          {{
                            !parseFloat(course.total_lessons)
                              ? 0
                              : parseFloat(
                                  computePercentage(
                                    progressByCourse[course.id]
                                      ? progressByCourse[course.id][
                                          'total_completed'
                                        ]
                                      : 0,
                                    course.total_lessons,
                                  ),
                                )
                          }}
                          %
                        </p>
                      </progress-circle>
                    </div>
                  </div>
                  <div v-else class="lock">
                    <i class="el-icon-lock"></i>
                  </div>
                </div>
                <div class="card-description">
                  <div class="vertical-center">
                    <div
                      v-html="course.description"
                      class="trim-desciption"
                    ></div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="block-avatar">
                    <el-avatar
                      class="custom-avatar"
                      :size="30"
                      src=""
                      @error="errorHandler"
                    >
                      <img
                        :src="
                          course.author.name === 'Greg Secker'
                            ? avatar_gregg
                            : avatar_joy
                        "
                      />
                    </el-avatar>
                    <div class="speaker-block">
                      <div style="color: #a2b0d5; font-size: 12px;">
                        Speaker:
                      </div>
                      <div class="speaker-name">{{ course.author.name }}</div>
                      <!-- <div class="speaker-name">{{ course.speaker }}</div> -->
                    </div>
                  </div>

                  <div
                    v-show="course.course_type === COURSE_TYPE_DEFAULT"
                    class="block-duration"
                  >
                    <div style="color: #a2b0d5; font-size: 12px;">
                      Total duration:
                    </div>
                    <div class="speaker-name">
                      {{ computeDuration(course.duration) }}
                    </div>
                    <!-- <div class="speaker-name">{{ course.duration }}</div> -->
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <component
      :is="currentComponent"
      @unload="unload()"
      :course="selectedCourse"
    ></component>

    <component :is="floatingComponent"></component>

    <!-- <disclamer
      v-if="_customer.customer.show_dialog.aus_compaliance === true"
    ></disclamer> -->
    <component :is="complianceComponent"></component>
  </div>
</template>

<script>
/* eslint-disable */
import json_courses from './dummy.json'
import CourseModal from './modal/CourseModal.vue'
import FloatingPreview from './modal/FloatingPreview.vue'
import Disclamer from './modal/Disclaimer.vue'
import { ProgressCircle } from 'vue-progress-circle'
export default {
  name: 'IndexVue',
  components: {
    CourseModal,
    FloatingPreview,
    ProgressCircle,
    Disclamer,
  },
  data() {
    return {
      card_bg: require('../assets/images/card-bg.png'),
      avatar: require('../assets/images/avatar.png'),
      avatar_gregg: require('../assets/images/authors/gregg.jpg'),
      avatar_joy: require('../assets/images/authors/joy.jpg'),
      dummy_data: json_courses,
      window: {
        width: 0,
        height: 0,
      },
      currentComponent: null,
      selectedCourse: null,
      courses: [],
      COURSE_TYPE_DEFAULT: 1,
      fill: { color: '#77BC43' },
      emptyColorFill: { color: '#ffffff' },
      floatingComponent: null,
      aus_compaliance: '',
      complianceComponent: null,
    }
  },
  computed: {
    progressByCourse() {
      let lessons = this.$store.getters._my_progress.lessons
      let groupedBycourses = lessons.reduce((i, item) => {
        const course_id = item.course_id
        if (!i[course_id]) {
          i[course_id] = []
        }
        i[course_id].push(item)
        return i
      }, {})
      const courses = []
      for (var index in groupedBycourses) {
        courses[index] = []
        let lessons = groupedBycourses[index]
        let completed = lessons.filter((lesson) => {
          return lesson.is_complete === 1
        })
        courses[index]['total_completed'] = completed.length
        let total_duration = 0
        for (let i = 0; i < lessons.length; i++) {
          total_duration += lessons[i]['duration']
        }
        let last_viewed_lesson = lessons
          .sort((a, b) => b.value - a.value)
          .reverse()

        courses[index]['last_viewed_lesson'] = last_viewed_lesson[0]
        courses[index]['total_duration'] = total_duration
        courses[index]['lesson_progress'] = lessons
      }
      return courses
    },
    _customer() {
      return this.$store.getters._customer
    },
  },
  beforeMount() {
    var current_url = window.location.href
    var url = new URL(current_url)
    var token = url.searchParams.get('token')
    if (token) {
      // check if url has token provided
      this.verifyToken(token)
    } else {
      this.getCourses()
      if (localStorage.getItem('token')) {
        this.token = localStorage.getItem('token')
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.getDetectedTimezone()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getDetectedTimezone() {
      var current_tz = this.$cookies.get('_detected_current_tz')
      console.log(current_tz, 'current_tz')
      if (current_tz === null) {
        var url_timezone =
          'https://api.ipgeolocation.io/timezone?apiKey=' +
          process.env.VUE_APP_COOKIE_KEY
        this.axios
          .get(url_timezone)
          .then((response) => {
            console.log(response.data.timezone, '- detected timezone')
            this.$cookies.set('_detected_current_tz', response.data.timezone)
            // this.$cookies.set('_detected_current_tz', 'Asia/Manila')
          })
          .catch((error) => {
            console.log('unable to get timezone')
          })
      }
    },
    computePercentage(partial, full) {
      let percentage = (100 * parseFloat(partial)) / parseFloat(full)
      // return parseFloat(percentage).toFixed(2)
      return Math.floor(percentage)
      // return Math.round(parseFloat(percentage).toFixed(2))
    },
    verifyToken(token) {
      let url = process.env.VUE_APP_API_URL + '/api/lmsapp/auth/login'

      this.axios
        .get(url, {
          params: {
            token: token,
          },
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('assignCustomer', response.data)
            localStorage.setItem('token', response.data.app_session.session_key)

            this.token = localStorage.getItem('token')
            this.getCourses()
            this.$store.dispatch('getProgress', this.token)
            if (response.data.customer.show_dialog.aus_compaliance === true) {
              this.aus_compaliance = this.$store.getters._customer.customer.show_dialog.aus_compaliance
              this.complianceComponent = Disclamer
            }
          } else {
            this.clearSession()
          }
        })
        .catch((err) => {
          if (localStorage.getItem('token')) {
            this.use_region = this.selected_region = this.region = localStorage.getItem(
              'region',
            )
            this.token = localStorage.getItem('token')
          }
          // loading.close();
        })
    },
    computeDuration(value) {
      // let temp_data = 60000;
      let hours = Math.floor(value / 3600)
      value %= 3600
      let minutes = Math.floor(value / 60)
      let seconds = value % 60
      return hours + ' hrs and ' + minutes + ' mins'
    },
    getBackground(image) {
      if (image.attachment_absolute_path) {
        return image.attachment_absolute_path
      } else {
        return this.card_bg
      }
    },
    handleMinimize() {
      this.minimizeComponent = FloatingPreview
      // this.handleClose()
    },
    handleResize() {
      if (
        process.env.VUE_APP_SHOW_BOOKING === 'true'
          ? (this.floatingComponent = FloatingPreview)
          : (this.floatingComponent = null)
      )
        this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    getCourses() {
      const loading = this.$loading({
        lock: true,
        // spinner: "el-icon-loading",
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.$store.dispatch('getCourses').then((response) => {
        let courses = response.data.data
        loading.close()
        this.courses = courses
      })
    },
    computeCourseProgress(data) {
      let courses = data
      let lesson_progress = this.$store.getters._progress_lessons
      courses.forEach((course) => {
        let x = 0 //percentage
        let i = 0 //completed
        lesson_progress.forEach((lesson) => {
          if (lesson.course_id === course.id) {
            x = x + lesson.duration
            if (lesson.is_complete === 1) {
              i = i + 1
            }
          }
        })
        course['completed_lesson'] = i
        course['total_duration_played'] = x
        course['total_progress'] = ((x / course.duration) * 100).toFixed(2)

        i = 0

        var newArray = []
        newArray = lesson_progress.filter(function (el) {
          return el.course_id === course.id
        })

        var maximum = Math.max.apply(
          Math,
          newArray.map((o) => o.viewed_at),
        )

        let last_viewed_lesson = newArray.find(
          (item) => item.viewed_at === maximum,
        )

        course['last_viewed_lesson'] = last_viewed_lesson
      })

      this.courses = courses
    },
    getCourse(course) {
      course.last_viewed_lesson = this.progressByCourse[course.id]
        ? this.progressByCourse[course.id]['last_viewed_lesson']
        : null

      this.selectedCourse = course
      this.currentComponent = CourseModal
    },
    unload() {
      this.currentComponent = null
      this.selectedCourse = null
    },
    errorHandler() {},
    getOtherBooking() {},
  },
}
</script>

<style scoped>
.trim-desciption {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* number of lines to show */
  line-clamp: 7;
  -webkit-box-orient: vertical;
}
</style>

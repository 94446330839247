<template>
  <div>
    <el-col
      :span="24"
      class="pdf-container"
      :style="{ height: height + ' !important' }"
    >
      <iframe
        ref="pdfIframe"
        :src="url"
        v-if="loading === false"
        id="pdf_iframe"
        width="100%"
        :height="height + 'px'"
        frameborder="0"
      >
      </iframe>
    </el-col>
  </div>
</template>

<script>
/* eslint-disable */
import pdf from "vue-pdf";
// import VuePdfEmbed from 'vue-pdf-embed'
import PDFJSExpress from "@pdftron/pdfjs-express-viewer";
export default {
  name: "PdfViewer",
  props: {
    selected: {
      required: true,
      type: String,
    },
    height: {
      required: false,
      type: Number,
    },
    lessonId: {
      required: false,
      type: Number,
    },
  },
  components: {
    pdf,
    PDFJSExpress,
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      pdfUrl: this.selected,
      data: this.selected,
      url: "",
      loading: true,
    };
  },
  mounted() {
    this.pdfUrl = this.selected;
    let data = {
      url: this.selected,
      height: this.height,
    };
    this.url =
      process.env.VUE_APP_API_URL +
      "/getpdf?url=" +
      this.selected +
      "&height=" +
      this.height +
      "&id=" +
      this.lessonId;
    if (this.url) {
      this.loading = false;
      this.$emit("pdfstatus");
    }

    this.$nextTick(() => {
      if (this.$refs.pdfIframe) {
        document.querySelector("iframe").onload = () => {
          this.$emit("loaded");
        };
      }
    });
  },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _ from 'lodash';
Vue.use(Vuex);
/* eslint-disable */
const store = new Vuex.Store({
	namespaced: true,
	state: {
		customer: {},
		progress: {
			courses: [],
			chapters: [],
			lessons: [],
			to_sync: [],
		},
		courses: [],
		progress_lessons: [],
	},
	mutations: {
		ASSIGN_CUSTOMER: (state, payload) => {
			state.customer = payload;
			window.localStorage.setItem(
				'customer_id',
				payload.customer.id
			);
		},
		STORE_PROGRESS: (state, payload) => {
			state.progress.lessons = payload;
			state.progress_lessons = payload;
		},
		UPDATE_LESSON_PROGRESS: (
			state,
			{ lessons, to_sync }
		) => {
			state.progress.lessons = lessons;
			state.progress.to_sync = to_sync;
		},
		SYNC_PROGRESS: (state, payload) => {},
		ASSIGN_COURSES: (state, payload) => {
			/* eslint-disable */
			state.courses = payload;
			// let progress = state.progress;
			// let courses = state.courses;

			// console.log(progress, 'progress');
			// console.log(courses, 'courses 11');
			// // if (lesson_progress.length > 0) {
			// courses.forEach((course) => {
			// 	let x = 0; //percentage
			// 	let i = 0; //completed
			// 	console.log(course, 'before');
			// 	progress.lessons.forEach(
			// 		(lesson) => {
			// 			console.log(
			// 				lesson,
			// 				course,
			// 				'add course duration'
			// 			);
			// 			if (
			// 				lesson.course_id ===
			// 				course.id
			// 			) {
			// 				x =
			// 					x +
			// 					lesson.duration;
			// 			}
			// 			if (
			// 				lesson.is_complete ===
			// 				1
			// 			) {
			// 				i = i + 1;
			// 			}
			// 		}
			// 	);
			// 	course['total_duration_played'] =
			// 		x;
			// 	course['total_progress'] = (
			// 		(x / course.duration) *
			// 		100
			// 	).toFixed(2);
			// 	course['completed_lesson'] = i;

			// 	var newArray = [];
			// 	newArray =
			// 		progress.lessons.filter(
			// 			function (el) {
			// 				return (
			// 					el.course_id ===
			// 					course.id
			// 				);
			// 			}
			// 		);

			// 	var maximum = Math.max.apply(
			// 		Math,
			// 		newArray.map(
			// 			(o) => o.viewed_at
			// 		)
			// 	);

			// 	let last_viewed_lesson =
			// 		newArray.find(
			// 			(item) =>
			// 				item.viewed_at ===
			// 				maximum
			// 		);

			// 	course['last_viewed_lesson'] =
			// 		last_viewed_lesson;
			// });
			// // }
			// console.log(courses, 'courses after');
			// state.courses = courses;
		},
	},
	actions: {
		async assignCustomer(
			{ commit, state },
			value
		) {
			commit('ASSIGN_CUSTOMER', value);
		},
		async getCustomerBookings(
			{ commit, state, rootState },
			payload
		) {
			let data = {
				convert_dates_to_region:
					rootState.customer.region,
			};
			console.log(rootState, 'rootstate');
			// Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsInNjb3BlcyI6IioiLCJleHAiOjE3NDEyNjQwNDV9.DGyqYjXk4AkZgoRlOz_fhdJyxJ_UZxgb_WgnTfHgZTo`,
			const config = {
				headers: {
					// Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsInNjb3BlcyI6IioiLCJleHAiOjE3NDEyNjQwNDV9.DGyqYjXk4AkZgoRlOz_fhdJyxJ_UZxgb_WgnTfHgZTo`,
					Authorization: `Bearer ${process.env.VUE_APP_SC2_TOKEN}`,
					'Content-Type':
						'application/json',
					'X-Requested-With':
						'XMLHttpRequest',
					Accept: 'application/json',
					'X-Session-Key':
						localStorage.getItem(
							'token'
						),
				},
			};
			return new Promise(
				(resolve, reject) => {
					var url =
						process.env
							.VUE_APP_SC2_URL +
						'/api/v1/bookings?customer_id=' +
						rootState.customer
							.customer.id;

					// if(payload.video_id !== 0) {
					axios
						.get(url, config)
						.then((response) => {
							resolve(response);
						})
						.catch((error) => {
							reject(error);
						});
					// }
				}
			);
		},
		async getCourses(
			{ commit, state },
			value
		) {
			return new Promise(
				(resolve, reject) => {
					const config = {
						headers: {
							Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
							'Content-Type':
								'application/json',
							'X-Requested-With':
								'XMLHttpRequest',
							Accept: 'application/json',
							'X-Session-Key':
								localStorage.getItem(
									'token'
								),
						},
					};

					var url =
						process.env
							.VUE_APP_API_URL +
						'/api/lmsapp/courses';
					axios
						.get(url, config)
						.then((response) => {
							commit(
								'ASSIGN_COURSES',
								response.data.data
							);
							resolve(response);
						})
						.catch((error) => {});
				}
			);
		},
		async getProgress(
			{ commit, state },
			value
		) {
			return new Promise(
				(resolve, reject) => {
					const config = {
						headers: {
							//Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
							'Content-Type':
								'application/json',
							'X-Requested-With':
								'XMLHttpRequest',
							Accept: 'application/json',
							'X-Session-Key':
								localStorage.getItem(
									'token'
								),
						},
					};

					var url =
						process.env
							.VUE_APP_API_URL +
						'/api/lmsapp/progress';
					axios
						.get(url, config)
						.then((response) => {
							commit(
								'STORE_PROGRESS',
								response.data.data
							);
							resolve(response);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			);
		},
		async updateLessonProgress(
			{ commit, state },
			value
		) {
			var lessons = state.progress.lessons;
			var to_sync = state.progress.to_sync;

			const isVideoCompleted = _.findIndex(
				lessons,
				(o) => {
					return (
						o.lesson_id ==
							value.lesson_id &&
						o.is_complete == 1 &&
						o.course_id ==
							value.course_id &&
						o.chapter_id ==
							value.chapter_id &&
						o.video_id ==
							value.video_id
					);
				}
			);

			if (isVideoCompleted >= 0) {
				return;
			}

			const index = _.findIndex(
				lessons,
				(o) => {
					return (
						o.video_id ==
							value.video_id &&
						o.course_id ==
							value.course_id &&
						o.chapter_id ==
							value.chapter_id &&
						o.lesson_id ==
							value.lesson_id
					);
				}
			);
			if (index === -1) {
				lessons.push({
					video_id: value.video_id,
					course_id: value.course_id,
					casesafe_id:
						value.casesafe_id,
					chapter_id: value.chapter_id,
					lesson_id: value.lesson_id,
					duration: value.duration,
					is_complete:
						value.duration > 90
							? 1
							: 0,
					customer_id:
						value.customer_id,
					viewed_at: value.viewed_at,
				});
			} else {
				lessons.splice(index, 1, {
					video_id: value.video_id,
					course_id: value.course_id,
					chapter_id: value.chapter_id,
					lesson_id: value.lesson_id,
					duration: value.duration,
					is_complete:
						value.duration > 90
							? 1
							: 0,
					casesafe_id:
						value.casesafe_id,
					viewed_at: value.viewed_at,
				});
			}

			if (
				to_sync.indexOf(
					value.lesson_id
				) === -1
			) {
				to_sync.push(value.lesson_id);
			}
			commit('UPDATE_LESSON_PROGRESS', {
				lessons,
				to_sync,
			});
		},
		async syncProgressState(
			{ commit, state },
			value
		) {
			let lessonsToSync = _.filter(
				state.progress.lessons,
				(o) => {
					return state.progress.to_sync.includes(
						o.lesson_id
					);
				}
			);

			if (lessonsToSync.length === 0) {
				return;
			}

			return new Promise(
				(resolve, reject) => {
					const config = {
						headers: {
							//Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
							'Content-Type':
								'application/json',
							'X-Requested-With':
								'XMLHttpRequest',
							Accept: 'application/json',
							'X-Session-Key':
								localStorage.getItem(
									'token'
								),
						},
					};

					var url =
						process.env
							.VUE_APP_API_URL +
						'/api/lmsapp/lessons/progress';
					var data = {
						payload: lessonsToSync,
					};
					axios
						.post(url, data, config)
						.then((response) => {
							resolve(response);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			);
		},
	},
	getters: {
		_my_progress: (state) => state.progress,
		_customer: (state) => state.customer,
		_progress_lessons: (state) =>
			state.progress_lessons,
		_progress_courses: (state) =>
			state.progress_courses,
	},
});

export default store;

<template>
  <div>
    <el-dialog
      id="disclaimer_dialog"
      title="-"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="100%"
      style=""
      top="0vh"
      :fullscreen="true"
      class="dialog_disclaimer"
    >
      <div class="loader-overlay">
        <i class="fas fa-times custom_close_btn" @click="handleClose()"></i>
        <div class="loader-overlay__inner">
          <div class="disclamer">
            <div class="disclamer-content">
              <center><img width="150" :src="logo" /></center>
              <br />
              <p style="margin-top: 20px;">
                <center>Disclaimer</center>
              </p>
              <p>
                Learn to Trade Pty Ltd (ACN:138178542, AFSL:339557) provides
                general information and educational courses and materials only.
                This is not an offer to buy/sell financial products. We do not
                provide personal advicenor do we consider the needs, objectives
                or circumstances of any individual. Financial products are
                complex and all entail risk of loss. Over-the-counterderivative
                and foreign exchange products are considered speculative because
                they are highly leveraged and carry risk of loss beyond your
                initial investment, hence should only be traded with capital you
                can affordto lose. Please ensure you obtain professional advice
                to ensure trading or investing in any financial products is
                suitable for your circumstances, and ensure you obtain, read and
                understand any applicable offer document.
              </p>
              <p>
                <center>General Advice Warning</center>
              </p>
              <p>
                LTT will only provide you with general advice, not personal
                advice. That means, we will not take into account your personal
                objectives, financial situation or needs, even if they are
                known. Accordingly, the advice may not be appropriate for you.
                We may provide general advice regarding trade size, the level of
                margin needed and risk management techniques appropriate for our
                trading strategies. Financial products are complex and all
                entail risk of loss. Over-the-counter derivative and foreign
                exchange products are considered speculative because they are
                highly leveraged and carry risk of loss beyond your initial
                investment, hence should only betraded with capital you can
                afford to lose. Please ensure you obtain professional advice to
                ensure trading or investing in any financial productsis suitable
                for your circumstances, and ensure you obtain, read and
                understand any applicable offer document. Learn to Trade Pty Ltd
                (ABN 98138 178 542 AFSL 339557).
              </p>
              <p>
                <center>Risks of trading Margin FX and CFDs</center>
              </p>
              <p>
                LTT’s training can help you manage but not avoid the risks of
                trading margin Foreign eXchange and Contracts For Differences.
                Your broker’s Product Disclosure Statement details these risks
                including:
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; Many untrained novice traders lose
                much of their capital
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; High leverage up to 100:1 greatly
                magnifies both risks and returns
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; Adverse market moves can lose you
                all your equity and more, or at least cause your broker to call
                for more equity margin
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; Stop losses are recommended to
                manage risk but may trigger “at market” orders which may be
                filled past the intended order level
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; Markets can be volatile,jumpy, and
                even “gap” on surprise news/events
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; Some markets can be especially
                illiquid and volatile if thinly traded such as cryptocurrencies
              </p>
              <p>
                - &nbsp; &nbsp; &nbsp; &nbsp; You could become an unsecured
                creditor if your broker goes bust and inadequately protects its
                client trust accounts
              </p>

              <center>
                <el-button
                  type="success"
                  style="margin-top: 5% !important;"
                  @click="closeDisclamer()"
                >
                  Accept and Close
                </el-button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Disclamer',

  data() {
    return {
      disclamer: true,
      initialLoad: false,
      dialogVisible: true,
      logo: require('../../assets/images/logo.png'),
    }
  },
  created() {
    this.dialogVisible = true
  },
  destroyed() {
    this.dialogVisible = false
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    closeDisclamer: function () {
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.custom_close_btn {
  display: block;
  position: absolute;
  right: 42px !important;
  top: 38px !important;
  font-size: 25px;
  cursor: pointer;
}

.el-dialog {
  background-color: transparent !important;
}

.disclamer {
  display: inline-block;
  position: relative;
  height: 100%;
}

.loader-overlay__inner {
  display: inline-block;
  position: relative;
  height: 100%;
}

.loader-overlay {
  display: block;
  position: inherit;
  height: 100%;
}
</style>
